import Image from 'next/image';
import ConnectButton from '../ConnectButton';
import { useFormatter, useTranslations } from 'use-intl';
import BlockieIdenticon from '../../elements/Blockie';
import { ellipsis } from '../../../../utils/ellipsis';
import { useDisconnect } from 'wagmi';
import { useState } from 'react';
import { WalletIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Button from '../../elements/Button';
import Link from 'next/link';
import Drip from '../Drip';
import { Chain, formatEther } from 'viem';
import CurrencyToggle from '../../widgets/CurrencyToggle';

const HeaderMobile = ({
  address,
  balance,
  chain,
  floorStaked,
  lockedUntil,
  sweepPower,
  currentYield,
}: {
  address?: `0x${string}`;
  balance?: bigint;
  chain: Chain;
  floorStaked: bigint;
  lockedUntil?: number;
  sweepPower: bigint;
  currentYield?: string;
}) => {
  const t = useTranslations();
  const [showMenu, setShowMenu] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { number, dateTime } = useFormatter();

  const { disconnect } = useDisconnect();

  return (
    <header className="px-4 py-2 border-b border-gray-400">
      <div className="container mx-auto max-w-7xl flex justify-between relative">
        <Image
          src="/images/logo.svg"
          height={23}
          width={120}
          alt="FLOOR"
          className="pr-6 mr-4 border-r border-gray-400"
        />
        <aside className="h-10 flex">
          <div className="flex items-center space-x-2">
            <CurrencyToggle />
            <Drip size="small" />
            <Button size="small" onClick={() => setShowDetails((x) => !x)}>
              <WalletIcon className="h-5 w-5 text-white" />
            </Button>
            <Button size="small" onClick={() => setShowMenu(true)}>
              <Bars3Icon className="h-5 w-5 text-white" />
            </Button>
          </div>
          {showDetails && (
            <div className="bg-gray-400 rounded-xl px-4 absolute top-full left-0 right-0 mt-4 z-10">
              <section className="flex justify-between items-center py-4">
                <p>{t('Header.staking')}</p>
                <Link href={`/stake`} legacyBehavior={true} passHref={true}>
                  <Button variant="secondary">{t('Header.manage')}</Button>
                </Link>
              </section>
              <section className="py-4 border-t border-[#343434]">
                <dl className="grid grid-cols-3 gap-2 text-xxs">
                  <div className="space-y-1">
                    <dd className="font-display text-xs">
                      {' '}
                      {number(Number(formatEther(floorStaked)), {
                        maximumFractionDigits: 2,
                      })}
                    </dd>
                    <dt className="text-gray-200">{t('Header.staked')}</dt>
                  </div>
                  <div className="space-y-1">
                    <dd className="font-display text-xs">
                      {' '}
                      {lockedUntil
                        ? dateTime(lockedUntil, {
                            day: '2-digit',
                            month: 'short',
                            year: '2-digit',
                          })
                        : '-'}
                    </dd>
                    <dt className="text-gray-200">{t('Header.locked')}</dt>
                  </div>
                  <div className="space-y-1">
                    <dd className="font-display text-xs">
                      {' '}
                      {number(Number(formatEther(sweepPower)), {
                        maximumFractionDigits: 2,
                      })}
                    </dd>
                    <dt className="text-gray-200">{t('Header.power')}</dt>
                  </div>
                </dl>
              </section>
              <footer className="text-center pt-1 pb-3 flex justify-center">
                <ConnectButton
                  className="w-full"
                  connectedRender={
                    <button
                      type="button"
                      onClick={() => {
                        disconnect();
                        setShowDetails(false);
                      }}
                      className="text-xs underline hover:no-underline"
                    >
                      {t('Header.disconnect')}
                    </button>
                  }
                />
              </footer>
            </div>
          )}
        </aside>
      </div>
      {showMenu && (
        <div className="fixed top-0 bottom-0 left-0 right-0 h-screen w-full bg-black flex flex-col z-10 px-4 py-6">
          <div className="h-10 mt-px">
            <Button
              onClick={() => setShowMenu(false)}
              size="small"
              className="ml-auto"
            >
              <XMarkIcon className="text-white h-5 w-5" />
            </Button>
          </div>
          <nav className="py-10">
            <ul className="text-4xl text-center space-y-2">
              {(['wars', 'sweeps', 'stake', 'treasury'] as const).map((k) => (
                <li key={k}>
                  <Link
                    href={`/${k}`}
                    className="hover:text-brand-blue"
                    onClick={() => setShowMenu(false)}
                  >
                    {t(`App.routes.${k}`)}
                  </Link>
                </li>
              ))}
              <li>
                <Link
                  href={`/migrate`}
                  className="hover:text-brand-red"
                  onClick={() => setShowMenu(false)}
                >
                  {t(`App.routes.migrate`)}
                </Link>
              </li>
            </ul>
          </nav>
          <ConnectButton
            className="w-full"
            connectedRender={
              <div className="text-center">
                <aside className="bg-gray-400 rounded-full p-4 text-xs inline-flex items-center divide-x divide-[#343434] hover:bg-gray-400/90">
                  <span className="pr-4 flex items-center">
                    <WalletIcon className="h-5 w-5 mr-2 text-gray-500" />
                    {balance ? number(Number(formatEther(balance))) : '-'}{' '}
                    {chain.nativeCurrency.symbol}
                  </span>
                  <span className="pl-4 flex items-center">
                    <BlockieIdenticon address={address || ''} diameter={20} />
                    <span className="mx-2">{ellipsis(address || '')}</span>
                  </span>
                </aside>
              </div>
            }
          />
        </div>
      )}
    </header>
  );
};

export default HeaderMobile;
