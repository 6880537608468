import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import Button, { ButtonProps } from '../../elements/Button';
import { getChainConstant } from '@/domain/chain';
import { Addresses } from '@/domain/addresses';
import useChainId from '@/hooks/useChainId';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { erc20Abi } from 'viem';

const Drip = ({ size }: { size?: ButtonProps['size'] }) => {
  const chainId = useChainId();
  const { data, writeContract, status } = useWriteContract();

  const drip = () =>
    writeContract({
      abi: [
        ...erc20Abi,
        {
          inputs: [],
          name: 'drip',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      address: getChainConstant(Addresses, chainId, 'Drip'),
      functionName: 'drip',
      args: [],
    });

  const { fetchStatus } = useWaitForTransactionReceipt({ hash: data });

  if (chainId === 1) {
    return null;
  }

  return (
    <Button
      size={size}
      variant="secondary"
      onClick={drip}
      processing={fetchStatus === 'fetching'}
    >
      <ArrowDownTrayIcon className="h-5 w-5 mr-1" />
      {'Faucet'}
    </Button>
  );
};

export default Drip;
