import { getChainConstant } from '@/domain/chain';
import { SubgraphUrl } from '@/domain/subgraph';
import useChainId from '@/hooks/useChainId';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';
import useEpochIterationTimestamp from '../epochs/useEpochIterationTimestamp';
import { Address } from 'viem';

const useLockedUntilTimestamp = ({
  userAddress,
}: { userAddress?: Address } = {}) => {
  const { address } = useAccount();
  const chainId = useChainId();

  const addr = userAddress || address;

  const { data: epochLockedUntil } = useQuery({
    queryKey: ['epochLockedUntil', addr, chainId],
    queryFn: async () => {
      const data = (await querySubgraph({
        url: getChainConstant(SubgraphUrl, chainId),
        query: `{
          voteLock(id: "${addr?.toLowerCase()}") {
            lockEnd
          }
        }`,
      })) as { voteLock: { lockEnd: string } };

      return data.voteLock?.lockEnd || null;
    },

    enabled: !!addr,
  });

  return useEpochIterationTimestamp({ epoch: epochLockedUntil });
};

export default useLockedUntilTimestamp;
