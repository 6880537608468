import { useMemo, useState } from 'react';
import { useFormatter } from 'use-intl';
import { Chain } from 'viem';
import useCurrentEpoch from '@/application/epochs/useCurrentEpoch';
import { getChainConstant } from '@/domain/chain';
import { Addresses } from '@/domain/addresses';
import Price from '../../elements/Price';

const MetaBar = ({
  chain,
  endTime,
  epochYield,
  floorPrice,
}: {
  chain: Chain;
  endTime: number;
  epochYield?: bigint;
  floorPrice?: bigint;
}) => {
  const [now] = useState(() => Date.now());
  const { relativeTime } = useFormatter();
  const { data: currentEpoch } = useCurrentEpoch();

  const diff = endTime - now;

  // seconds under 1 minute, minutes if under 1 hour, hours if under 1 day, days if under 2 weeks
  const unit =
    diff < 60_000
      ? 'seconds'
      : diff < 3_600_000
        ? 'minutes'
        : diff < 86_400_000
          ? 'hours'
          : diff < 1_296_000_000
            ? 'days'
            : 'weeks';

  return (
    <div className="px-2 py-1 bg-gray-800">
      <div className="container mx-auto max-w-7xl">
        <p className="text-xxs text-center uppercase hide-scroll overflow-x-scroll whitespace-nowrap">
          {
            'The Floor Wars are on hold while the team works to relaunch in the future. '
          }
          <a
            href="https://forum.floor.xyz/t/fip77-project-new-wind/600"
            className="text-brand-blue underline hover:no-underline"
            rel="noreferrer nofollow"
          >
            {'Read more'}
          </a>
          {'.'}
        </p>
      </div>
    </div>
  );
};

export default MetaBar;
