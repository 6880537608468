import useChainId from '@/hooks/useChainId';
import { Address, erc20Abi } from 'viem';
import { useReadContract } from 'wagmi';

const useBalanceOf = ({
  token,
  address,
}: {
  token: Address;
  address?: Address;
}) => {
  const chainId = useChainId();
  return useReadContract({
    functionName: 'balanceOf',
    abi: erc20Abi,
    address: token,
    args: address ? [address] : undefined,
    chainId: chainId,
  });
};

export default useBalanceOf;
