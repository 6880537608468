'use client';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
  useFloating,
  useRole,
  useClick,
  useInteractions,
  FloatingOverlay,
} from '@floating-ui/react';
import Button from '../../elements/Button';
import { useLocalStorage } from 'usehooks-ts';

const useBottomScrollListener = (
  ref: MutableRefObject<any>,
  onBottomReached: () => void,
) => {
  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onBottomReached();
        }
      },
      { threshold: 1.0 },
    );

    // Observe the bottom element
    const target = ref.current;
    observer.observe(target);

    // Cleanup on unmount or ref change
    return () => {
      observer.unobserve(target);
    };
  }, [ref, onBottomReached]);
};

const TermsOfService = () => {
  const [agreedToTermsOfService, setAgreedToTermsOfService] = useLocalStorage<
    string | null
  >('floor.agreedToTermsOfService', null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const bottomMarkerRef = useRef(null);

  useBottomScrollListener(bottomMarkerRef, () => setButtonDisabled(false));

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const click = useClick(context);
  const role = useRole(context);

  const { getFloatingProps } = useInteractions([click, role]);

  useEffect(() => {
    if (agreedToTermsOfService) {
      setIsOpen(false);
      console.info(`Terms agreed to on ${Date.now()}`);
    } else {
      setIsOpen(true);
    }
  }, [agreedToTermsOfService]);

  return (
    <>
      {isOpen && (
        <FloatingOverlay
          lockScroll
          className="bg-gray-800 bg-opacity-80 flex flex-col justify-center items-center z-20"
        >
          <div ref={refs.setFloating} {...getFloatingProps()}>
            <div className="flex flex-col text-sm text-gray-200 container mx-auto bg-black shadow-lg p-6 rounded-2xl max-w-md max-h-[520px] h-full my-20">
              <h1 className="text-2xl font-bold mb-4 text-gray-100">
                FloorDAO Terms of Service Agreement
              </h1>
              <div className="overflow-y-scroll overflow-hidden flex-1 styled-scroll">
                <p className="mb-2">
                  <strong>Acceptance of Agreement</strong>: By selecting “I
                  Agree,” you acknowledge your acceptance of the terms outlined
                  in this Agreement, confirming your understanding of the
                  following:
                </p>

                <ol className="list-decimal pl-5">
                  <li className="mb-2">
                    <strong>Nature of FloorDAO</strong>: FloorDAO is a
                    decentralized finance project built on blockchain
                    technology. Participation is entirely at your own risk.
                  </li>
                  <li className="mb-2">
                    <strong>{`'As Is' Provision`}</strong>: The FloorDAO service
                    is provided {`"as is,"`} with no guarantees regarding its
                    security. It consists of immutable code accessible through
                    various user interfaces.
                  </li>
                  <li className="mb-2">
                    <strong>Decentralized Operation</strong>: No single entity
                    controls the FloorDAO protocol. Protocol-related decisions
                    are made by a decentralized collective of participants
                    responsible for its governance and maintenance.
                  </li>
                  <li className="mb-2">
                    <strong>FloorDAO’s Role</strong>: FloorDAO does not
                    individually provide, manage, operate, administer, or
                    control any trading interfaces. FloorDAO solely maintains
                    the governance and staking interfaces described herein.
                  </li>
                  <li className="mb-2">
                    <strong>Governance Participation</strong>: You may engage in
                    governance by staking FLOOR tokens following the summarized
                    rules and parameters, and/or by participating in discussions
                    on the FloorDAO Discord channel.
                  </li>
                  <li className="mb-2">
                    <strong>Changes to Rules and Parameters</strong>: The rules
                    and parameters of the FloorDAO protocol and its governance
                    are subject to change at any time.
                  </li>
                  <li className="mb-2">
                    <strong>Condition of Use</strong>: Your use of FloorDAO is
                    conditional upon your agreement to the FloorDAO Terms of
                    Use, available [here].
                  </li>
                  <li className="mb-2">
                    <strong>Jurisdictional Laws</strong>: The applicable laws
                    for your use of FloorDAO vary based on your location. We
                    recommend consulting legal counsel in your jurisdiction for
                    any related inquiries.
                  </li>
                  <li className="mb-2">
                    <strong>Nature of Agreement</strong>: This agreement does
                    not constitute a partnership. You acknowledge that FloorDAO
                    is a decentralized protocol provided on an {`"as is"`}{' '}
                    basis.
                  </li>
                  <li className="mb-2">
                    <strong>Release of Claims</strong>: You release all current
                    and future claims against FloorDAO related to your use of
                    the protocol, the FLOOR token, FloorDAO governance, and any
                    other aspect of the protocol.
                  </li>
                  <li className="mb-2">
                    <strong>Indemnification</strong>: You agree to indemnify and
                    hold harmless FloorDAO and its affiliates from any costs
                    related to your use of the FloorDAO protocol.
                  </li>
                  <li>
                    <strong>Prohibited Jurisdictions</strong>: You confirm that
                    you are not accessing the protocol from any prohibited
                    jurisdictions, including but not limited to the United
                    States of America and countries sanctioned by the United
                    States Office of Foreign Asset Control (OFAC).
                  </li>
                </ol>
                <div ref={bottomMarkerRef} />
              </div>
              <div className="px-4">
                <Button
                  onClick={() => {
                    console.log('Agreed on', Date.now().toString());
                    setIsOpen(false);
                    setAgreedToTermsOfService(Date.now().toString());
                  }}
                  className={`w-full my-4 transition-opacity ${
                    buttonDisabled ? 'opacity-70' : 'opacity-100'
                  }`}
                  type="button"
                >
                  {'I Agree'}
                </Button>
              </div>
            </div>
          </div>
        </FloatingOverlay>
      )}
    </>
  );
};

export default TermsOfService;
