import Image from 'next/image';
import ConnectButton from '../ConnectButton';
import { useFormatter, useTranslations } from 'use-intl';
import BlockieIdenticon from '../../elements/Blockie';
import { useDisconnect } from 'wagmi';
import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { WalletIcon } from '@heroicons/react/24/outline';
import Button from '../../elements/Button';
import Link from 'next/link';
import ChainButton from '../ChainButton';
import { Chain, formatEther } from 'viem';
import User from '../../elements/User';
import Drip from '../Drip';
import CurrencyToggle from '../../widgets/CurrencyToggle';

export const isActivePage = (path: string, hrefs: string[]) => {
  return hrefs.some(
    (href) => path === href || path.split('/')[1] === href.split('/')[1],
  );
};

const Header = ({
  activePath,
  address,
  balance,
  chain,
  floorStaked,
  lockedUntil,
  sweepPower,
}: {
  activePath: string;
  address?: `0x${string}`;
  balance?: bigint;
  chain: Chain;
  floorStaked: bigint;
  lockedUntil?: number;
  sweepPower: bigint;
  currentYield?: string;
}) => {
  const t = useTranslations();
  const [showDetails, setShowDetails] = useState(false);
  const { number, dateTime } = useFormatter();

  const { disconnect } = useDisconnect();

  return (
    <header className="px-4 border-b border-gray-400">
      <div className="container mx-auto max-w-7xl flex relative items-center">
        <Link
          href="/"
          className="pr-8 mr-4 border-r border-gray-400 inline-flex py-2 w-[140px] xl:w-auto"
        >
          <Image src="/images/logo.svg" height={23} width={140} alt="FLOOR" />
        </Link>

        <ul className="text-sm font-semibold text-center space-x-2 xl:space-x-4 2xl:space-x-6 flex items-center">
          {[
            { key: 'wars' as const, paths: ['/wars'] },
            { key: 'stake' as const, paths: ['/stake', '/unstake'] },
          ].map((k) => (
            <li
              key={k.key}
              className={`border-y-2 border-transparent transition-colors ${
                isActivePage(activePath, k.paths)
                  ? 'border-b-white text-brand-blue'
                  : ''
              }`}
            >
              <Link
                href={`/${k.key}`}
                className="hover:text-brand-blue mx-4 py-5 block"
              >
                {t(`App.routes.${k.key}`)}
              </Link>
            </li>
          ))}
          <li
            className={`border-y-2 border-transparent transition-colors ${
              isActivePage(activePath, ['/migrate'])
                ? 'border-b-brand-red text-brand-red'
                : ''
            }`}
          >
            <Link
              href={`/migrate`}
              className="hover:text-brand-red mx-4 py-5 block"
            >
              {t(`App.routes.migrate`)}
            </Link>
          </li>
        </ul>
        <aside className="h-12 flex self-center items-center ml-auto space-x-4">
          {process.env.NODE_ENV === 'development' && <ChainButton />}
          {<Drip size="small" />}
          <CurrencyToggle />
          <ConnectButton
            connectedRender={
              <button
                type="button"
                onClick={() => setShowDetails((x) => !x)}
                className="bg-gray-400 rounded-full px-4 py-3 text-xs flex items-center divide-x divide-[#343434] hover:bg-gray-400/90 whitespace-nowrap"
              >
                <span className="pr-4 flex items-center">
                  <WalletIcon className="h-5 w-5 mr-2 text-gray-500" />
                  {balance ? number(Number(formatEther(balance))) : '-'}{' '}
                  {chain.nativeCurrency.symbol}
                </span>
                <span className="pl-4 flex items-center overflow-hidden">
                  <BlockieIdenticon address={address || ''} diameter={20} />
                  <span className="mx-2 truncate">
                    <User address={address} />
                  </span>
                  <ChevronDownIcon
                    className={`h-5 w-5 ${
                      showDetails ? 'rotate-180' : ''
                    } will-change-transform transition-transform`}
                  />
                </span>
              </button>
            }
          />
          {showDetails && (
            <div className="bg-gray-400 rounded-xl px-4 absolute top-full -right-2 mt-3 w-full max-w-md z-20">
              <section className="flex justify-between items-center py-4">
                <p>{t('Header.staking')}</p>
                {!isActivePage(activePath, ['/stake', '/unstake']) && (
                  <Link href={`/stake`} legacyBehavior={true} passHref={true}>
                    <Button variant="secondary">{t('Header.manage')}</Button>
                  </Link>
                )}
              </section>
              <section className="py-4 border-t border-[#343434]">
                <dl className="grid grid-cols-3 gap-6 text-xxs">
                  <div className="space-y-1">
                    <dd className="font-display text-xs truncate">
                      {number(Number(formatEther(floorStaked)), {
                        maximumFractionDigits: 2,
                      })}
                    </dd>
                    <dt className="text-gray-200">{t('Header.staked')}</dt>
                  </div>
                  <div className="space-y-1">
                    <dd className="font-display text-xs uppercase">
                      {lockedUntil ? 'Unlocked' : '-'}
                    </dd>
                    <dt className="text-gray-200">{t('Header.locked')}</dt>
                  </div>
                </dl>
              </section>
              <footer className="text-center pt-1 pb-3">
                <button
                  type="button"
                  onClick={() => {
                    disconnect();
                    setShowDetails(false);
                  }}
                  className="text-xs underline hover:no-underline"
                >
                  {t('Header.disconnect')}
                </button>
              </footer>
            </div>
          )}
        </aside>
      </div>
    </header>
  );
};

export default Header;
