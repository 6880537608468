import { Addresses } from '@/domain/addresses';
import { getChainConstant } from '@/domain/chain';
import useChainId from '@/hooks/useChainId';
import EpochManagerAbi from '@/infrastructure/abis/EpochManagerAbi';
import { useReadContract } from 'wagmi';

export const useEpochIterationTimestamp = ({
  epoch,
}: {
  epoch?: bigint | string | null;
}) => {
  const chainId = useChainId();

  return useReadContract({
    abi: EpochManagerAbi,
    address: getChainConstant(Addresses, chainId, 'EpochManager'),
    functionName: 'epochIterationTimestamp',
    args: [BigInt(epoch || 0)],
    query: {
      gcTime: Infinity,
      enabled: epoch != null,
    },
  });
};

export default useEpochIterationTimestamp;
