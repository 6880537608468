import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ConnectButton as RainbowConnectButton } from '@rainbow-me/rainbowkit';
import { mainnet } from 'viem/chains';

const ChainButton = () => {
  return (
    <RainbowConnectButton.Custom>
      {({ chain, openChainModal, openConnectModal, account, mounted }) => {
        if (mounted) {
          return (
            <button
              onClick={!account ? openConnectModal : openChainModal}
              className="text-xs rounded-lg p-1 inline-flex"
            >
              {chain?.name || mainnet.name}
              <ChevronDownIcon className="ml-1 h-4 w-4" />
            </button>
          );
        }
      }}
    </RainbowConnectButton.Custom>
  );
};

export default ChainButton;
