'use client';
import { useAccount, useBalance } from 'wagmi';
import Header from './Header';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import HeaderMobile from './HeaderMobile';
import { usePathname } from 'next/navigation';
import useSweepPower from '@/application/staking/useSweepPower';
import { Addresses } from '@/domain/addresses';
import { getChainConstant } from '@/domain/chain';
import useChainId from '@/hooks/useChainId';
import useLockedUntilTimestamp from '@/application/staking/useLockedUntilTimestamp';
import { mainnet } from 'viem/chains';
import { useChain } from '@/hooks/useChain';

const HeaderContainer = ({}) => {
  const pathName = usePathname();
  const [isMobile, setIsMobile] = useState(false);
  const chain = useChain();
  const { address } = useAccount();
  const { data } = useBalance({ address });

  const chainId = useChainId();

  const { data: sweepPower } = useSweepPower({ address });
  const { data: veFloorBalance } = useBalance({
    address,
    token: getChainConstant(Addresses, chainId, 'VeFloorStaking'),
  });

  const { data: lockedUntil } = useLockedUntilTimestamp();

  const { width } = useWindowSize() || {};

  useEffect(() => {
    setIsMobile(width < 1024);
  }, [width]);

  const props = {
    address,
    balance: data?.value,
    chain: chain || mainnet,
    activePath: pathName,
    floorStaked: veFloorBalance?.value || 0n,
    sweepPower: sweepPower || 0n,
    lockedUntil: lockedUntil ? Number(lockedUntil) * 1000 : undefined,
  } as const;

  if (isMobile) {
    return <HeaderMobile {...props} />;
  }

  return <Header {...props} />;
};

export default HeaderContainer;
