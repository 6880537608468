'use client';

import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import * as React from 'react';
import { WagmiProvider } from 'wagmi';
import messages from '../../messages/en.json';
import { config } from '../wagmi';
import { IntlProvider } from 'use-intl';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SettingsProvider } from '@/context/settings';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      placeholderData: keepPreviousData,
      staleTime: 1000 * 60 * 2.5,
      refetchInterval: 1000 * 60 * 5,
      refetchOnWindowFocus: true,
      queryKeyHashFn: (queryKey) => {
        return JSON.stringify(queryKey, (_, val) => {
          if (typeof val === 'bigint') {
            return `${val}`;
          }
          if (val && typeof val === 'object') {
            return Object.keys(val)
              .sort()
              .reduce((result, key) => {
                result[key] = val[key];
                return result;
              }, {} as any);
          }
          return val;
        });
      },
      retry: (x, y: any) => {
        if (x >= 3) {
          // Only retry 3 times
          return false;
        }
        if (
          y?.status &&
          typeof y.status === 'number' &&
          y.status >= 400 &&
          y.status < 500
        ) {
          // It's a 4xx error caused by bad inputs from the user
          // Don't keep retrying the request
          return false;
        }
        return true;
      },
    },
  },
});

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <SettingsProvider>
      <WagmiProvider config={config} reconnectOnMount={true}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            initialChain={Number(process.env.NEXT_PUBLIC_CHAIN_ID)}
            appInfo={{ appName: 'FLOOR' }}
            theme={darkTheme({
              accentColor: '#14F5DA',
              accentColorForeground: '#000',
            })}
            modalSize="compact"
            coolMode={true}
          >
            <IntlProvider
              locale="en"
              messages={messages}
              timeZone="Europe/London"
            >
              {children}
              <ReactQueryDevtools initialIsOpen={false} />
            </IntlProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </SettingsProvider>
  );
}
