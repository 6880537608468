'use client';
import useBalanceOf from '@/application/token/useBalanceOf';
import MigrateBanner from './MigrateBanner';
import { useChain } from '@/hooks/useChain';
import { getChainConstant } from '@/domain/chain';
import { Addresses } from '@/domain/addresses';
import { useAccount } from 'wagmi';
import { usePathname } from 'next/navigation';

const MigrateBannerContainer = () => {
  const pathname = usePathname();
  const chain = useChain();
  const { address } = useAccount();

  const { data: balanceFloor } = useBalanceOf({
    token: getChainConstant(Addresses, chain.id, 'OldTokenFloor'),
    address,
  });

  const { data: balanceSFloor } = useBalanceOf({
    token: getChainConstant(Addresses, chain.id, 'OldTokenSFloor'),
    address,
  });

  const { data: balanceGFloor } = useBalanceOf({
    token: getChainConstant(Addresses, chain.id, 'OldTokenGFloor'),
    address,
  });

  const canMigrate = [balanceFloor, balanceGFloor, balanceSFloor].some(
    (x) => x !== undefined && x > BigInt(1e18),
  );

  if (canMigrate && pathname !== '/migrate') {
    return <MigrateBanner />;
  }

  return null;
};

export default MigrateBannerContainer;
