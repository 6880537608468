import { useSettings } from '@/context/settings';
import { useEffect, useState } from 'react';

const CurrencyToggleWidget = () => {
  const [settings, updateSettings] = useSettings();

  const onCurrencyChange = () =>
    updateSettings({
      currency: settings.currency === 'ETH' ? 'USD' : 'ETH',
    });

  const isEth = settings.currency === 'ETH';

  return (
    <div
      className="flex flex-none space-x-1 cursor-pointer items-center"
      onClick={onCurrencyChange}
    >
      <div className={isEth ? 'text-brand-blue' : ''}>{'Ξ'}</div>
      <div className="w-10 flex items-center px-px border border-brand-blue/70 rounded-full h-6 relative">
        <span
          className={`bg-brand-blue absolute top-px left-px bottom-px w-5 h-5 rounded-full transition-all ${
            isEth ? 'ml-0' : 'ml-4'
          }`}
        />
      </div>
      <div className={!isEth ? 'text-brand-blue' : ''}>{'$'}</div>
    </div>
  );
};

// ensure we don't server side render the toggle initially
// as there is no localstorage and therefore the selected currency is
// not rendered properly
const CurrencyToggle = () => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    setRender(true);
  }, []);

  if (render) {
    return <CurrencyToggleWidget />;
  }

  return null;
};

export default CurrencyToggle;
