'use client';
import useEpochs from '@/application/epochs/useEpochs';
import MetaBar from './MetaBar';
import useFloorMarketPrice from '@/application/token/useFloorTokenPrice';
import { useChain } from '@/hooks/useChain';
import { useMemo } from 'react';
import useMinSweepAmount from '@/application/sweeps/useMinSweep';
import useCurrentEpochYield from '@/application/epochs/useCurrentEpochYield';

const MetaBarContainer = () => {
  const chain = useChain();
  const { data: epochs } = useEpochs();
  const { data: floorPrice } = useFloorMarketPrice();
  const { data: minSweep } = useMinSweepAmount();
  const { data } = useCurrentEpochYield();

  const [, , curentEpochYield] = data || [];
  const epoch = epochs?.[0];

  const epochYield = useMemo(() => {
    if (minSweep && curentEpochYield) {
      return curentEpochYield > minSweep ? curentEpochYield : minSweep;
    }

    return curentEpochYield || minSweep || 0n;
  }, [minSweep, curentEpochYield]);

  const endTime = epoch?.endTime
    ? Number(epoch.endTime) * 1000
    : // mainnet is 14 days, testnet is 2 days
      (Number(epoch?.startTime) + 24 * 60 * 60 * (chain.testnet ? 2 : 14)) *
      1000;

  return (
    <MetaBar
      chain={chain}
      endTime={endTime}
      epochYield={epochYield}
      floorPrice={floorPrice}
    />
  );
};

export default MetaBarContainer;
