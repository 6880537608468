'use client';
// import Image from 'next/image';
import Link from 'next/link';
import { useTranslations } from 'use-intl';

export interface FooterProps {}

const externalLinks = [] as const;

const Footer = ({}: FooterProps) => {
  const t = useTranslations('App');

  return (
    <footer className="py-10 px-4 border-t border-gray-400 mt-10">
      <div className="container mx-auto max-w-7xl">
        <div className="md:flex pt-8 items-start space-y-10 md:space-y-0">
          <ul className="sm:flex-1 text-sm text-center space-x-6 flex items-center">
            {(['stake', 'wars'] as const).map((k) => (
              <li key={k}>
                <Link href={`/${k}`} className="hover:text-brand-blue">
                  {t(`routes.${k}`)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
