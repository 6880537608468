import Link from 'next/link';

const MigrateBanner = () => {
  return (
    <aside className="bg-brand-red text-white text-xs p-1 text-center">
      {'You have old FLOOR tokens, '}
      <Link href="/migrate" className="underline hover:no-underline">
        {'migrate them now'}
      </Link>
    </aside>
  );
};

export default MigrateBanner;
