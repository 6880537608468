import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  injectedWallet,
  ledgerWallet,
  metaMaskWallet,
  rainbowWallet,
  trustWallet,
  walletConnectWallet,
  rabbyWallet,
  phantomWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { http } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';

const projectId = '10cb3ca6237e231405c2c6100823b689';

const wallets = [
  {
    groupName: 'Suggested',
    wallets: [
      injectedWallet,
      rabbyWallet,
      metaMaskWallet,
      rainbowWallet,
      phantomWallet,
      walletConnectWallet,
      coinbaseWallet,
    ],
  },
  {
    groupName: 'Other',
    wallets: [ledgerWallet, trustWallet],
  },
];

export const config = getDefaultConfig({
  appName: 'FLOOR',
  projectId,
  multiInjectedProviderDiscovery: true,
  transports: {
    [mainnet.id]: http(
      `https://eth-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`,
    ),
    [sepolia.id]: http(
      `https://eth-sepolia.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY_SEPOLIA}`,
    ),
  },
  wallets,
  chains: [mainnet, sepolia],
});
